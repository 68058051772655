import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`Deload week starts today`}</em></strong></p>
    <p>{`OHS/Snatch specific mobility & stretching`}</p>
    <p>{`then,`}</p>
    <p>{`30:00 amrap:`}</p>
    <p>{`10-Alternating DB Hang Power Snatches per arm`}{`*`}</p>
    <p>{`10-DB OHS’s per arm`}{`*`}</p>
    <p>{`12-GHD Situps`}</p>
    <p>{`12-Calorie Ski Erg`}</p>
    <p>{`14-D Ball Slams (30/20`}{`#`}{`)`}</p>
    <p>{`14-Burpees`}</p>
    <p><em parentName="p">{`*`}{`pick a light enough weight so you can correctly perform both the
snatches & single arm OHS’s`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      